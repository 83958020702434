<template>
  <div>
    <b-row>
      <b-col class="mt-2" cols="4" v-for="(item, index) in products" :key="index">
        <div
          :style="`border-radius: 10px; ${
            item.default ? 'border: 2px solid rgb(128 77 227)' : ''
          }`"
        >
          <b-card style="min-height: 195px">
            <div style="position: absolute; left: 42%; top: -10px">
              <b-badge
                v-for="(tag, key) in item.tags"
                :key="key"
                pill
                style="background-color: rgb(128 77 227)"
              >{{ tag }}</b-badge>
            </div>
            <div class="text-center">
              <span
                v-if="!abTestEnable"
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                style="font-size: 1.8rem; font-weight: 400; font-weight: bold"
              >
                {{ item.time_number }}
                <br>
                {{ item.time_unit }}
              </span>

              <span
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                :style="!abTestEnable ? 'font-size: 1.1rem; color: #b4b7bd' : 'font-size: 1.8rem;'"
                style="font-weight: bold"
              >
                <del v-if="!abTestEnable">{{ item.price }}</del>
                <div v-else>{{ item.price }}</div>
              </span>

              <span
                v-if="!abTestEnable"
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  'mt-2',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                style="font-size: 1.5rem; font-weight: bold"
              >{{ item.price_sale }}</span>
              <!-- <span
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                style="font-size: 1rem"
                >{{ item.time }}
              </span>-->
            </div>
            <div style="position: absolute; right: -20px; top: -4px">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editItem(index)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteItem(index)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card>
          <hr :class="[item.default ? 'custom-hr' : 'default-hr']">
          <b-card class="text-center">
            <span
              :class="[
                'text-truncate',
                'd-block',
                'w-100',
                item.default ? 'default-color' : 'footer-text-color',
              ]"
              style="font-size: 1.6rem; font-weight: bold"
            >{{ item.info_sale }}</span>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <div class="text-center mt-2">
      <b-button
        @click="openModal"
        size="sm"
        pill
        variant="gradient-primary"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add New</span>
      </b-button>
    </div>

    <b-modal
      id="content"
      ok-only
      ok-title="Save"
      centered
      title="Content"
      v-model="isOpen"
      @ok="save"
    >
      <b-form-group label="Tag" label-for="tag">
        <b-form-tags v-model="content.tags" input-id="tags-basic" class="mb-2" />
      </b-form-group>
      <b-form-group label="Name" label-for="name">
        <b-form-input id="name" name="name" placeholder="Name" v-model="content.name" />
      </b-form-group>
      <b-form-group label="Description" label-for="description">
        <b-form-input id="description" name="description" placeholder="description" v-model="content.description" />
      </b-form-group>
      <b-form-group v-if="!abTestEnable" label="Time" label-for="time">
        <b-row class="border">
          <b-col cols="6">
            <b-form-group label="Number" label-for="number">
              <b-form-input
                id="number"
                name="number"
                placeholder="Number"
                v-model="content.time_number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Unit" label-for="unit">
              <b-form-input id="unit" name="unit" placeholder="Unit" v-model="content.time_unit" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="Store ID" label-for="store_id">
        <b-form-input v-model="content.store_id" id="store_id" placeholder="Store ID" />
      </b-form-group>
      <b-form-group v-if="!abTestEnable" label="Price Sale" label-for="price_sale">
        <b-form-input
          id="price_sale"
          name="price_sale"
          placeholder="Price Sale"
          v-model="content.price_sale"
        />
      </b-form-group>
      <b-form-group label="Price" label-for="price">
        <b-form-input id="price" name="price" placeholder="Price" v-model="content.price" />
      </b-form-group>

      <b-form-group label="Price Per Month" label-for="info_sale">
        <b-form-input
          id="info_sale"
          name="info_sale"
          placeholder="Info sale"
          v-model="content.info_sale"
        />
      </b-form-group>

      <b-form-group label="Discount Info" label-for="discount_info">
        <b-form-input
          id="discount_info"
          name="discount_info"
          placeholder="Discount Info"
          v-model="content.discount_info"
        />
      </b-form-group>

      <b-form-checkbox
        v-model="content.default"
        name="default"
        class="custom-control-primary"
      >Default</b-form-checkbox>
      <div class="pt-1">
        <b-form-group label="Auto Renew">
          <div class="d-flex">
            <b-form-checkbox
              class="custom-control-primary"
              name="is_free_checkbox"
              switch
              v-model="content.auto_renew"
            />
            <strong>{{ content.auto_renew ? "Yes" : "No" }}</strong>
          </div>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CardTextField",
  props: {
    products: { type: Array, required: true },
    abTestEnable: { type: Boolean, default: false },
  },
  data() {
    return {
      content: {},
      isOpen: false,
      indexUpdate: null,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    deleteItem(index) {
      this.products.splice(index, 1);
    },
    editItem(index) {
      this.content = this.products[index];
      this.indexUpdate = index;
      this.openModal();
    },
    save() {
      if (this.content.default) {
        this.products.forEach((item, index) => {
          if (this.indexUpdate !== index) {
            item.default = false;
          }
        });
      }
      if (this.indexUpdate || this.indexUpdate == 0) {
        this.products[this.indexUpdate] = this.content;
        this.indexUpdate = null;
      } else {
        this.products.push(this.content);
      }

      this.content = {};
    },
  },
};
</script>
<style scoped>
.default-color {
  color: black;
}
.custom-color {
  color: black;
}
.footer-text-color {
  color: black;
}
.text-body {
  color: black !important;
}
.card {
  margin-bottom: 0;
}
.custom-hr {
  border-top: 2px solid rgb(128 77 227);
  margin: 0;
}
.default-hr {
  border-top: 2px solid white;
  margin: 0;
}
.row {
  margin: 0;
  border-radius: 5px;
}
</style>
