<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Sale Popup" class="mb-3" />
      </b-col>
      <b-col md="2 text-right">
        <b-button @click="openModal" variant="primary">
          + Add Sale Popup
        </b-button>
      </b-col>
    </b-row>
    <form-generator :schema="filter_schema" :model="filter" style="width: 100%" />
    <b-media>
      <section class="py-3">
        <h2 class="mb-2">Sale Popup Management</h2>
        <b-row>
          <b-table
            striped
            hover
            responsive
            :items="lists"
            :fields="headerTables"
          >
            <template #cell(stt)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell()="data">
              {{ data.value }}
            </template>
            <template #cell(action)="data">
              <b-button
                variant="gradient-warning"
                style="margin-right: 5px"
                class="btn-icon"
                @click="editSalePopup(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                @click="confirmDelete(data.item._id)"
                variant="gradient-danger"
                class="btn-icon"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-row>
      </section>
    </b-media>
    <sale-popup-modal
      :data-update="dataUpdate"
      @create="create"
      @update="updateSalePopup"
      ref="salePopupModal"
    />
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../service";
import SalePopupModal from "./_components/SalePopupModal.vue";
export default {
  components: {
    SalePopupModal,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 90,
      lists: [],
      dataUpdate: null,
      headerTables: [
        "stt",
        {
          key: "title",
          label: "Title",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "language",
          label: "Language",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      filter_schema: [
        { cols: 2, fields: [{ label: 'Language', field: 'language', input_type: 'select', options: this.$store.getters['language/language_options']}] 
        },{
          cols: 4, fields: [{label: "Type", field: 'type_key', input_type: 'text'}]
        }
      ],
      filter: {}
    };
  },
  watch: {
    current_page() {
      this.getList();
    },
    filter: {
      handler() {
        if (this.filter.type_key){
          this.filter.type = {$regex: this.filter.type_key, "$options": 'i'}
          delete this.filter.type_key
        }
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.getList();
  },
  methods: {
    openModal() {
      this.dataUpdate = null;
      this.$refs.salePopupModal.openModal();
    },
    async getList() {
      let response = await service.getList({
        query: JSON.stringify(this.filter),
        page: this.current_page,
        limit: this.items_perpage,
      });
      if (response.data.type === "DATA") {
        this.lists = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    async deleteSalePopup(id) {
      await service.delete({ id: id });
      this.getList();
    },
    async create(sale_popup) {
      let response = await service.create(sale_popup);
      if (response.data.type == "DATA") {
        this.getList();
      }
    },
    editSalePopup(sale_popup) {
      this.dataUpdate = sale_popup;
      this.$refs.salePopupModal.openModal();
    },
    async updateSalePopup(sale_popup) {
      await service.update({ data: JSON.stringify(sale_popup) });
      this.getList();
      this.dataUpdate = null;
    },
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteSalePopup(id);
          }
        });
    },
  },
};
</script>
