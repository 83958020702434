<template>
  <b-modal
    id="modal-scrollable"
    scrollable
    title="Sale Popup Content"
    cancel-title="Close"
    ok-title="Save"
    cancel-variant="outline-secondary"
    v-model="isOpen"
    @ok="save"
    size="xl"
  >
    <b-form-group label="Option Type" label-for="option_type">
      <b-form-select
        v-model="sale_popup.option_type"
        class="custom-control-primary"
        :options="OPTION_TYPES_OPTIONS"
      />
    </b-form-group>
    <discount-form v-if="isDiscountOption" :discount-info="sale_popup.discount_info" :option-type="sale_popup.option_type" />
    <b-form-group label="Title" label-for="title">
      <b-form-input v-model="sale_popup.title" id="title" placeholder="Title" />
    </b-form-group>
    <b-form-group label="Subtitle" label-for="subtitle">
      <b-form-input v-model="sale_popup.subtitle" id="subtitle" placeholder="Subtitle" />
    </b-form-group>
    <b-form-group label="Type" label-for="type">
      <b-form-input v-model="sale_popup.type" id="type" placeholder="Type" />
    </b-form-group>
    <b-form-group label="Language" label-for="language">
      <!-- <b-form-select v-model="sale_popup.language" :options="languages" /> -->
      <language-select :language="sale_popup.language" @selectedLanguage="selectedLanguage" />
    </b-form-group>
    <b-form-group label="Content" label-for="content">
      <ui-component-list :component-list="sale_popup.content" />
    </b-form-group>

    <b-form-group label="AB Test Enable" label-for="ab_enable">
      <b-form-checkbox
        v-model="sale_popup.ab_enable"
        class="custom-control-primary"
        name="check-button"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="CheckIcon" />
        </span>
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="!sale_popup.ab_enable" label="Product" label-for="product">
      <b-card>
        <product-form-modal :products="sale_popup.products" />
      </b-card>
    </b-form-group>

    <b-form-group v-if="sale_popup.ab_enable" label="Products A" label-for="products_a">
      <b-card>
        <product-form-modal :products="sale_popup.products_a" :ab-test-enable="sale_popup.ab_enable" />
      </b-card>
    </b-form-group>

    <b-form-group v-if="sale_popup.ab_enable" label="Products B" label-for="products_b">
      <b-card>
        <product-form-modal :products="sale_popup.products_b" :ab-test-enable="sale_popup.ab_enable" />
      </b-card>
    </b-form-group>

    <b-form-group label="Button Text" label-for="button_text">
      <b-form-input v-model="sale_popup.button_text" />
    </b-form-group>

    <b-form-group label="Close Button Text" label-for="close_button_text">
      <b-form-input v-model="sale_popup.close_button_text" />
    </b-form-group>

    <b-form-group label="Button Sub Text" label-for="button_sub_text">
      <b-form-input v-model="sale_popup.button_sub_text" />
    </b-form-group>

    <b-form-group label="Icons" label-for="icons">
      <b-card>
        <icon-form-modal :icons="sale_popup.icons" />
      </b-card>
    </b-form-group>
    <b-form-group label="Alert Message" label-for="alert_message">
      <b-form-input v-model="sale_popup.alert_message" />
    </b-form-group>
    
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button class="float-left mr-1" variant="dark" @click="copyToClipboard">
          <b-spinner v-if="isLoading" small class="mr-50" />Copy
        </b-button>
        <b-button
          class="float-left"
          variant="dark"
          :disabled="isLoading"
          @click="pasteFromClipboard"
        >
          <b-spinner v-if="isLoading" small class="mr-50" />Paste
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button class="float-right" variant="primary" @click="ok()">Save</b-button>
        <b-button class="float-right mr-1" variant="outline-secondary" @click="cancel()">Close</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import ProductFormModal from "./ProductFormModal.vue";
import IconFormModal from "./IconFormModal.vue";
import DiscountForm from './DiscountForm.vue'
import { OPTION_TYPES, OPTION_TYPES_OPTIONS } from "../../constants";
export default {
  components: {
    ProductFormModal,
    IconFormModal,
    DiscountForm,
  },
  props: {
    dataUpdate: { type: Object, default: null },
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
      sale_popup: {
        title: null,
        type: null,
        language: "vi",
        content: [],
        button_text: null,
        products: [],
        products_a: [],
        products_b: [],
        icons: [],
        close_button_text: null,
        ab_enable: false,
        option_type: 0,
        discount_info: {},
      },
      OPTION_TYPES_OPTIONS,
    };
  },
  computed: {
    isDiscountOption() {
      return this.sale_popup.option_type && (this.sale_popup.option_type === OPTION_TYPES.DISCOUNT)
    },
  },
  watch: {
    dataUpdate: function (newValue) {
      if (newValue) {
        this.sale_popup = { ...this.sale_popup, ...newValue };
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    save() {
      if (this.dataUpdate) {
        this.$emit("update", this.sale_popup);
      } else {
        this.$emit("create", this.sale_popup);
      }
      this.resetData();
    },
    resetData() {
      this.sale_popup = {
        title: null,
        type: null,
        language: "vi",
        content: [],
        button_text: null,
        products: [],
        products_a: [],
        products_b: [],
        icons: [],
        close_button_text: null,
        ab_enable: false,
        option_type: 0,
        discount_info: {},
      };
    },
    selectedLanguage(language) {
      this.sale_popup.language = language;
    },

    async copyToClipboard() {
      this.isLoading = true
      let copy = JSON.parse(JSON.stringify(this.sale_popup))
      if (copy._id) { delete copy._id }

      await navigator.clipboard.writeText(JSON.stringify(copy))
      this.isLoading = false
    },
    async pasteFromClipboard() {
      this.isLoading = true
      const paste = await navigator.clipboard.readText()
      if (JSON.parse(paste)) {
        this.sale_popup = JSON.parse(paste)
      }

      this.isLoading = false
    },
  },
};
</script>
<style scoped>
.d-block {
  font-size: 1.1rem !important;
}
</style>
